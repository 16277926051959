<template>
  <div class="row">
    <div class="col-sm-12 mt-2">
      <div class="card">
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <h3 class="text-center text-primary">Category Bisac</h3>
          <br />
          <div class="row justify-content-center mb-2">
            <div class="col-sm-2">
              <b-form-select
                id="currency"
                class="mb-2"
                v-model="status"
                :options="statusOptions"
                required
                @change="onStatusChange"
              ></b-form-select>
            </div>
            <div class="col-sm-5">
              <input
                type="text"
                class="form-control"
                placeholder="type name of category bisac..."
                @input="debounceSearch"
              />
              <span v-if="typing"> <em> searching... </em> </span>
            </div>
            <router-link
              class="btn btn-primary mr-3"
              :to="{ path: 'category-bisac/create' }"
            >
              <i class="fa fa-plus"></i>
              Add Category Bisac
            </router-link>
          </div>
          <b-row class="justify-content-end">
            <div class="col-sm-auto pb-2">
              Count :
              <strong class="pl-2">
                {{ formatPriceBasic(totalRows) }}
              </strong>
            </div>
          </b-row>
          <b-table
            class="mt-2"
            sticky-header="500px"
            :items="category_bisac"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            hover
            responsive="xl"
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}.
            </template>

            <template #cell(name)="row">
              <router-link
                :to="{ path: 'category-bisac/edit/' + row.item.id }"
                class="link"
              >
                {{ row.item.name }}
              </router-link>
            </template>

            <template #cell(is_active)="row">
              <div v-if="row.item.is_active">
                <span class="badge badge-pill badge-success">Active</span>
              </div>
              <div v-else>
                <span class="badge badge-pill badge-danger">In Active</span>
              </div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading...
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'CategoryBisac',
  data() {
    return {
      message: '',
      typing: null,
      currentPage: 1,
      perPage: 20,
      debounce: null,
      fields: [
        { key: 'No', label: 'No.', thStyle: { maxWidth: '15px' } },
        {
          key: 'name',
          label: 'Name',
        },
        { key: 'code', label: 'Code' },
        { key: 'slug', label: 'Slug' },
        { key: 'is_active', label: 'Status' },
      ],
      status: null,
      statusOptions: [
        { value: null, text: 'Status' },
        { value: 1, text: 'Active' },
        { value: 2, text: 'Inactive' },
      ],
    };
  },
  watch: {
    currentPage: function() {
      this.getCategoryBisac();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Category Bisac | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.category_bisac.isLoading,
      isError: (state) => state.category_bisac.isError,
      totalRows: (state) => state.category_bisac.totalRows,
      category_bisac: (state) => state.category_bisac.items,
    }),
  },
  mounted() {
    this.getCategoryBisac();
  },
  methods: {
    ...mapActions('category_bisac', [
      'fetchCategoryBisac',
      'findCategoryBisac',
      'fetchCategoryBisacById',
    ]),

    getCategoryBisac() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
        is_active: this.status,
      };
      if (this.message) {
        payload.name = this.message;
      }
      this.fetchCategoryBisac(payload);
    },

    debounceSearch(event) {
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        let payload = {
          page: this.currentPage,
          limit: this.perPage,
          is_active: this.status,
        };
        if (this.message) {
          payload.name = this.message;
        }
        this.fetchCategoryBisac(payload);
      }, 600);
    },

    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    onStatusChange() {
      let payload = {
        is_active: this.status,
        page: this.currentPage,
        limit: this.perPage,
      };
      if (this.message) {
        payload.name = this.message;
      }
      this.fetchCategoryBisac(payload);
    },
  },
};
</script>
